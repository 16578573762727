<template lang="">
<div class="settingContent">
    <div class="handleBox flex flex-jc-sb flex-ai-c">
        <h3 style="">{{$route.name}}</h3>
    </div>
    <div class="contentBox" style="width:100%">
        <el-row :gutter="12">
            <el-col :span="4" v-for="(item,index) in list" :key="index">
                <!-- hover     always-->
                <el-card :shadow="item.id==id?'always':'hover'" :style="{textAlign:'center',background:item.id==id?'#4DB6AC':'',color:item.id==id?'#fff':''}" @click.native="handleChoose(item.id)">
                    <p style="font-size:30px;">￥{{item.fee_now}}</p>
                    <p style="text-decoration: line-through;margin:6px 0">原价：{{item.fee_old}}</p>
                    <p>{{item.name}}（{{item.time_month}}个月）</p>
                </el-card>
            </el-col>
        </el-row>
        <div style="margin-top:50px;textAlign:center">
            <el-divider content-position="left">支付码</el-divider>
            <el-row>
                <el-col :span="5" :offset="1">
                    <el-card>
                        <img :src="domain+codeUrl" alt="支付二维码" style="width:100%">
                        <p>请使用微信扫一扫<br>扫描二维码支付</p>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'payment',
    data() {
        return {
            list: [],
            id: '',
            codeUrl: '',
			order_no:'',
			timer:null
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        // 获取列表
        getList() {
            this.http.post('/admin.sysFee/index', {
                curr: 1,
                row: 999,
            }).then(res => {
                this.list = res.list;
				this.handleChoose(this.list[0].id)
            });
        },
        handleChoose(index) {
            if (index == this.id) return false;
            this.id = index;
            this.$nextTick(() => {
				if(this.timer){
					clearInterval(this.timer);
				}
                this.getCode();
				this.timer = setInterval(()=> {
                    this.checkStatus()
                }, 3000)
            })
        },
        getCode() {
            this.http.post('/admin.sysFee/payQrcode', {
                id: this.id
            }).then(re => {
                this.codeUrl = re.img;
				this.order_no = re.order_no;
            })
        },
		checkStatus() {
            this.http.post('/admin.sysFee/payQrcodeCheck', {
                order_no: this.order_no
            }, false).then(re => {
                if (Number(re) == 1) {
					if(this.timer){
						clearInterval(this.timer);
					}
                    this.$message({
                        type: 'success',
                        message: '支付成功!'
                    });
                    setTimeout(() => {
                        this.$router.push('/overview');
                    }, 1000)
                }
            });
        }
    },
	beforeDestroy: function() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
}
</script>

<style lang="css" scoped>
</style>
